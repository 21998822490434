<template>
  <v-app>
    <Header title="Anmeldung" />
    <v-main>
      <div style="max-width: 300px" class="mx-auto mt-12">
          <div><v-img :src="images.logo" /></div>
      </div>
      <v-card class="mx-auto mt-12" max-width="400" elevation="4">

        <v-form @submit.prevent="onSubmit(email, password);">
          <v-card-text>
            <v-text-field
              v-model="email"
              placeholder="max@mustermann.de"
              label="E-Mail"
              name="login"
              prepend-icon="person"
              type="text"
     

            ></v-text-field>
            <v-text-field
              v-model="password"
              placeholder="Geheim123"
              id="password"
              label="Kennwort"
              name="password"
              prepend-icon="lock"
              type="password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text type="submit">Anmelden</v-btn>

            <v-btn text color="orange" class="overline" href="/kennwort-vergessen">Kennwort vergessen</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-main>
    <Footer />
    <AlertDialog />
  </v-app>
</template>

<script>
import { LOGIN, KENNWORT_ANFORDERN} from "@/store/actions.type";
import { mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AlertDialog from "@/components/AlertDialog.vue";


export default {
  computed: {
    ...mapGetters(["isLoading"])
  },
  name: "Login",
  components: {
    Header,
    Footer,
    AlertDialog
  },  
  data() {
    return {
      dialog: false,
      email: null,
      password: null,
      images: {
        logo: require('../assets/logo.png')
      }
    };
  },
  methods: {
    checkMeta() {
      if (this.$route.meta) {
        if (this.$route.meta.kennwortVersenden) {
            this.$store.dispatch(KENNWORT_ANFORDERN, this.$route.params);
        }
      }
    },
    onSubmit(email, password) {
      this.$store
        .dispatch(LOGIN, { email, password })
        .then(() => this.$router.push({ name: "home" })).catch(()=> {
        });
    }
  }
  ,mounted() {
    this.checkMeta();
  },
};
</script>